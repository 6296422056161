<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card p-0 mb-half" style="margin-top: 3px;">
          <div class="card-header p-0 m-0 bg-light-primary">
            <div class="card-title ml-1 p-half d-flex align-items-center">
              <p-icon name="bi-plus" size="40px" color="primary" class="mr-1" />
              <h4 class="p-0 m-0">
                New Income
              </h4>
            </div>
          </div>
          <div class="d-flex flex-wrap p-1">
            <dx-util-select-box v-model="incomeForm.categoryId"
              class="mr-half" style="max-width: 150px;"
              :data-source="incomeCategories" display-expr="categoryName"
              value-expr="id" label="Category"
            />
            <div class="mr-half" style="padding-top: 8px;">
              <dx-util-button icon="plus" hint="Add/Edit New Categories" type="success" @click="manageCategories" />
            </div>
            <dx-util-date-box v-model="incomeForm.date"
              class="mr-half" style="max-width: 150px;"
              label="Date"
            />
            <dx-util-select-box
              v-model="incomeForm.storeId"
              :data-source="prepareOptions(stores)"
              display-expr="text"
              value-expr="value"
              label="Store"
              class="mr-half"
              style="max-width: 150px;"
            />
            <dx-util-text-box v-model="incomeForm.description"
              class="mr-half flex-grow-1"
              label="Description"
            />
            <dx-util-number-box v-model="incomeForm.amount"
              class="mr-half" style="max-width: 100px;"
              label="Amount" :format="{ type: 'currency', precision: 2 }"
            />
            <div style="padding-top: 8px;">
              <dx-util-button text="Create" class="d-block" type="success" @click="createIncome" />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header p-0 m-0 bg-light-primary">
            <div class="card-title ml-1 p-half d-flex align-items-center">
              <p-icon name="bi-cash-coin" size="40px" color="primary" class="mr-1" />
              <h4 class="p-0 m-0">
                All Incomes
              </h4>
            </div>
          </div>
          <dx-data-grid
            id="tasksGrid"
            ref="incomesGridRef"
            height="calc(100vh - 320px)"
            :data-source="incomesDataSource"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            key-expr="id"
            :customize-columns="customizeColumns"
            @row-removed="searchByFilter"
            @option-changed="onOptionChanged"
            @initialized="onInitialized"
            @content-ready="onContentReady"
            @toolbar-preparing="onToolbarPreparing($event)"
            @editor-preparing="onEditorPreparing"
          >
            <!--region    ::DataGrid base components -->
            <dx-sorting mode="single" />
            <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
            <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
            <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-editing
              mode="row"
              :allow-updating="true"
              :allow-adding="false"
              :allow-deleting="true"
              :use-icons="true"
            />
            <dx-column-fixing :enabled="true" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
            <!--endregion ::DataGrid base components -->
            <dx-column data-field="date" caption="Date" data-type="date" :format="'yyyy-MM-dd'" :width="100" />
            <dx-column data-field="description" caption="Description" cell-template="descriptionTemplate" />
            <template #descriptionTemplate="{data}">
              <div class="text-wrap">
                {{ data.value }}
              </div>
            </template>
            <dx-column data-field="categoryName" caption="Category" cell-template="categoryNameTemplate" :width="300">
              <dx-lookup
                :data-source="incomeCategories"
                display-expr="categoryName"
                value-expr="id"
              />
            </dx-column>
            <template #categoryNameTemplate="{data}">
              <div>
                {{ data.value }}
              </div>
            </template>
            <dx-column data-field="sourceType" caption="Source Type" cell-template="sourceTypeTemplate" width="110" />
            <template #sourceTypeTemplate="{data}">
              <div :class="resolveSourceType(data.value)" class="badge d-block">
                {{ data.value }}
              </div>
            </template>
            <dx-column v-if="false" data-field="currency" />
            <dx-column data-field="amount" :format="{ type: 'currency', precision: 2 }" :width="100" />
            <dx-column type="buttons" caption="Actions" width="120">
              <dx-button name="edit" />
              <dx-button name="delete" />
            </dx-column>
            <!-- v-if does not work for datagrid buttons -->
            <template #filterToolbar>
              <div class="d-flex">
                <div class="mr-half">
                  <dx-util-select-box
                    v-model="selectedStore"
                    :data-source="stores"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="searchByFilter"
                  />
                </div>
                <dx-util-tag-box v-model="filter.categoryIds"
                  class="mr-half" style="min-width: 400px;"
                  :data-source="incomeCategories" display-expr="categoryName"
                  value-expr="id"
                  placeholder="Select category"
                  :show-selection-controls="true"
                  :show-multi-tag-only="false"
                  :multiline="false"
                  :max-displayed-tags="3"
                  @value-changed="searchByFilter"
                />
                <div class="mr-half">
                  <date-selection default-date="last7Days" @setdates="onDatesSelection" />
                </div>
              </div>
            </template>
          </dx-data-grid>
        </div>
      </div>
      <income-categories :component-id="categoriesCompId" type="Income" @category-added="getIncomeCategories" />
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import IncomeExpenseSearchFilter from '@/http/models/search-filters/IncomeExpenseSearchFilter.js'
import DateSelection from '@/views/dashboard/statistics/components/DateSelection.vue'
import Filters from '@robustshell/mixins/filters'
import { v4 as uuidv4 } from 'uuid'
import useCurrentUser from '@/libs/app/current-user'
import companyService from '@/http/requests/company/companyService'
import { getMarketplaceList } from '@/enums/marketplaceEnum'
import { getFulfillmentMethodList } from '@/enums/fulfillmentType.enum'
import Pager from '@/@core/dev-extreme/utils/pager'
import { ExpenseSourceTypeEnum } from '@/enums'
import { incomesDataSource } from './incomesDataSource.js'

const today = new Date()
export default {
  components: {
    'date-selection': DateSelection,
    'income-categories': () => import('./components/Categories.vue'),

  },
  mixins: [GridBase, Filters],
  data() {
    return {
      incomesDataSource,
      marketplaces: getMarketplaceList(),
      channels: getFulfillmentMethodList(),
      incomeCategories: [],
      categoriesCompId: '',
      filter: {
        beginDate: '',
        endDate: '',
        query: '',
        categoryIds: [],
      },
      onlyAssignedMe: false,
      incomeForm: {
        storeId: 0,
        description: '',
        date: today,
        categoryId: '',
        currency: '',
        amount: null,
      },
    }
  },
  setup() {
    const { userRoleName, userId, userCompanyId } = useCurrentUser()
    return {
      userRoleName, userId, userCompanyId,
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.incomesGridRef.instance
      return grid
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },

  },
  created() {
    this.getStores()
    this.getIncomeCategories()
    this.loadData()
  },
  methods: {
    prepareOptions(enumList) {
      return enumList.filter(option => option.value !== 'ALL' && option.value !== 0)
    },
    async createIncome() {
      await companyService.createIncome(this.incomeForm)
      this.searchByFilter()
    },
    onDatesSelection(e) {
      this.filter.beginDate = e.beginDate
      this.filter.endDate = e.endDate
      this.searchByFilter()
    },
    loadData() {
      IncomeExpenseSearchFilter.setDefaultFilters()
      IncomeExpenseSearchFilter.categoryType = 'Income'
      const filter = IncomeExpenseSearchFilter.getFilters()
      incomesDataSource.searchValue(filter)
    },
    async getIncomeCategories() {
      const pager = new Pager({})
      IncomeExpenseSearchFilter.setDefaultFilters()
      IncomeExpenseSearchFilter.categoryType = 'Income'
      const filter = IncomeExpenseSearchFilter.getFilters()
      const response = await companyService.getIncomeExpenseCategoriesByQuery(pager.staticPageable, filter)
      this.incomeCategories = response.content
    },
    resolveSourceType(type) {
      if (type === ExpenseSourceTypeEnum.Amazon.value) return 'badge-light-warning'
      if (type === ExpenseSourceTypeEnum.Generated.value) return 'badge-light-success'
      if (type === ExpenseSourceTypeEnum.Manual.value) return 'badge-light-primary'
      return ''
    },
    manageCategories() {
      this.categoriesCompId = uuidv4()
    },
    searchByFilter() {
      IncomeExpenseSearchFilter.storeId = this.selectedStore
      IncomeExpenseSearchFilter.q = this.filter.query
      IncomeExpenseSearchFilter.sourceType = this.filter.sourceType
      IncomeExpenseSearchFilter.categoryIds = this.filter.categoryIds
      IncomeExpenseSearchFilter.beginDate = this.filter.beginDate
      IncomeExpenseSearchFilter.endDate = this.filter.endDate
      IncomeExpenseSearchFilter.categoryType = 'Income'
      const taskFilter = IncomeExpenseSearchFilter.getFilters()
      incomesDataSource.searchValue(taskFilter)
      incomesDataSource.reload()
    },
    onOptionChanged(e) {
      if (e.fullName === 'searchPanel.text') {
        this.filter.query = ''
        this.filter.query = e.value
      }
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      e.editorOptions.onValueChanged = arg => {
        const query = arg.component.option('value')
        if (!query) {
          this.filter.query = query.trim()
          e.component.searchByText('')
          this.searchByFilter()
        }
      }
      e.editorOptions.onKeyDown = arg => {
        if (arg.event.keyCode === 13) {
          let query = arg.component.option('value')
          query = query.trim()
          arg.component.option('value', query)
          this.filter.query = query
          this.searchByFilter()
          e.component.searchByText(query)
        }
        if (arg.event.keyCode === 27) {
          e.component.searchByText('')
          arg.component.option('value', '')
          this.filter.query = ''
          this.searchByFilter()
        }
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            incomesDataSource.reload()
          },
        },
        location: 'after',
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.text-wrap {
 overflow-wrap: break-word;
 word-break: break-word;
 max-width: 100%;
}

</style>
