/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import companyService from '@/http/requests/company/companyService'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: true }]
    sort.forEach(obj => {
      if (obj.selector === 'estimateDuration') {
        // eslint-disable-next-line no-param-reassign
        obj.selector = 'estimate'
      }
      if (obj.selector === 'priority') {
        // eslint-disable-next-line no-param-reassign
        obj.selector = 'priorityOrder'
      }
    })
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortQuery(sort)

    const pageableQuery = `${pager.toCreatePageable()}&sort=${pager.toCreateSortOrder()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await companyService.getIncomesByQuery(pageableQuery, filters)
    const data = response
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
  update: (key, values) => {
    const data = { id: key, ...values }
    companyService.upsertIncome(data)
  },
  remove: key => {
    companyService.deleteIncome(key)
  },
})

const incomesDataSource = new DataSource({
  store: store,
})

export {
  incomesDataSource,
}
